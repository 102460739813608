<template>
    <div class="CoReg_submit-container">
      <h3>상업등기 신청 페이지 제작중</h3>
      <p>상업등기 신청 페이지는 제작중 입니다.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CoReg_submit'
  }
  </script>
  
  <style scoped>
  .CoReg_submit-container {
    padding: 10px;
  }
  </style>